//import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const PaymentEvents = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
    const [payments, setPayments] = useState([])


    useEffect(()=>{
		const paymentSSE = new EventSource("https://asaliserver.powerhive.com/api/v1/payments/sse")
		//const paymentSSE = new EventSource("http://localhost:3000/api/v1/payments/sse")

		paymentSSE.onmessage = (event)=>{
			try {
				const data = JSON.parse(event.data)
				console.log(data)
				toast.success(data.message)
                setPayments(prev => [data.message, ...prev])
			} catch (error) {
				console.error("A problem occured fetching payment event", error);
			}
		}

		paymentSSE.onerror = (err) => {
			console.error("payment SSE encountered an error:", err);
			toast.error("Lost connection to live payment updates.");
			paymentSSE.close();
		};

		return () => {
			paymentSSE.close();
		};
	},[])

	return (
		<>
            <Box>
                {payments.length && payments.map((payment) => (
                    <Box
                        key={payment}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        p="15px"
                    >
                        <Typography
                            color={colors.greenAccent[500]}
                            fontWeight="600"
                            fontSize={25}
                        >
                            {payment}
                        </Typography>
                    </Box>
                ))}
            </Box>
		</>
	);
};

export default PaymentEvents;
