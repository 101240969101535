import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer } from "react-leaflet";
import BikeMarker, {Rider} from "../../components/MapComponents/BikeMarker";
import { useDispatch, useSelector } from "react-redux";
import { fetchMapData } from "../../state_management/cabinetMapData";
import SquareSpinner from "../../components/LoaderComponents/SquareSpinner";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CompanyLogo from "../../assets/images/phivelogo.avif"
import MarkerClusterGroup from "react-leaflet-cluster";

const Bikemap = () => {

    const dispatch = useDispatch()
    const [displayInfo, setDisplayInfo] = useState(null)
    const {mapData,firstMapDataLoading,  mapDataError} = useSelector(state => state.mapinfo)    
	const [bikelocations, setBikeLocations] = useState([])
	const [cabinetLocations, setCabinetLocations] = useState([])

    const transformMapData = (data)=>{
        return data?.map((item, index)=>{
            return {
                position: [Number(item?.latitude), Number(item?.longitude)],
                label:item?.display_name,
                active: item?.cabinet_data?.device_status === 0 ? true : false,
                stateOfCharge: item?.cabinet_data?.soc || []
            }
        })
    }


    useEffect(()=>{
        if(mapData?.length  > 0){
            setDisplayInfo(transformMapData(mapData))
			const cabinetLocations = displayInfo?.map(c => c?.position)
			setCabinetLocations(cabinetLocations)
        }
	// eslint-disable-next-line
    },[mapData])

    useEffect(()=>{
        dispatch(fetchMapData())

        const intervalId = setInterval(()=>{
            dispatch(fetchMapData());
        }, 180000)

        return () => clearInterval(intervalId)
    },[dispatch])

    useEffect(()=>{
        if(mapDataError){
            toast.error(mapDataError)
        }
    },[mapDataError])

	

	useEffect(()=>{
		const eventSource = new EventSource("https://asaliserver.powerhive.com/api/v1/bikes/sse")
		eventSource.onmessage = (event) =>{
			try {
				const data = JSON.parse(event.data)
				console.log(data)
				setBikeLocations(data)

			} catch (error) {
				console.error("Error parsing SSE data:", error);
			}
		}

		eventSource.onerror = (err) => {
			console.error("SSE encountered an error:", err);
			toast.error("Lost connection to live battery updates.");
			eventSource.close();
		};

		return () => {
			eventSource.close();
		};
	},[])

	useEffect(()=>{
		const swapSource = new EventSource("https://asaliserver.powerhive.com/api/v1/swaps/sse")
		// const swapSource = new EventSource("http://localhost:3000/api/v1/swaps/sse")

		swapSource.onmessage = (event)=>{
			try {
				const data = JSON.parse(event.data)
				console.log(data)
				toast.success(data.message)
			} catch (error) {
				console.error("A problem occured fetching swap event", error);
			}
		}

		swapSource.onerror = (err) => {
			console.error("swap SSE encountered an error:", err);
			toast.error("Lost connection to live swap updates.");
			swapSource.close();
		};

		return () => {
			swapSource.close();
		};
	},[])


	function haversineDistance([lat1, lon1], [lat2, lon2]) {
		const R = 6371000; // Radius of Earth in meters
		const toRad = angle => (angle * Math.PI) / 180;
	
		const dLat = toRad(lat2 - lat1);
		const dLon = toRad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
	
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;
	}
	
	function filterMovingBatteries(batteries, cabinets) {
		return batteries.filter(battery => 
			!cabinets?.some(cabinet => haversineDistance(battery, cabinet) <= 100)
		);
	}

    if(firstMapDataLoading || !displayInfo){
        return (
            <div style={{minHeight:"90vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <SquareSpinner height="100" width="100" color="#F7A33A"/>
            </div>
        )
    }


    
      return (
        <div style={{
            marginLeft:"-10px",
            marginTop:"-10px"
        }}>
			
          <MapContainer center={[-1.286389, 36.817223]} zoom={14}>
            <TileLayer url ="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
				<MarkerClusterGroup >
					{displayInfo?.map((marker, index) =>{
						return (<BikeMarker key={index} mapdata={marker}/> )
					})}
				</MarkerClusterGroup>
				{bikelocations?.length !== 0 && cabinetLocations?.length !== 0 &&(
					filterMovingBatteries(bikelocations, cabinetLocations)?.map((item, index)=>{
						return <Rider position={item} key={index}/>
					})
				)}
          </MapContainer>
          <span
            style={{
                position:"fixed",
                top: 20,
                right: 40, 
                zIndex:1000,

				display:"flex",
				alignItems:"center",
				gap:"10px",
				fontSize:"23px",
				fontWeight:"bold"
            }}
          >
            <img src={CompanyLogo} alt="company logo" width={150} height={50} style={{marginBottom:"-5px"}}/>
			Live Rider Map
          </span>
		  
        </div>
      );
}

export default Bikemap