import React from "react";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	TextField,
	Button,
	useTheme,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { tokens } from "../../../../theme";
const BundleGridToolbar = ({
	setCustomerCode,
	setBundleStatus,
	setPeriod,
	handleEditOpen,
	setEditCustomerModalOpen
}) => {
	const [customercodeDisplay, setCustomercodeDisplay] = useState("");
	const [bundlestatusDisplay, setBundlestatusDisplay] = useState("");
	const [periodDisplay, setPeriodDisplay] = useState("");
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const inputstyles = {
		"& label": {
			color: colors.blueAccent[500], // Default label color
		},
		"& label.Mui-focused": {
			color: colors.greenAccent[500], // Label color when focused
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: colors.blueAccent[500], // Default border color
			},
			"&:hover fieldset": {
				borderColor: colors.greenAccent[500], // Border color on hover
			},
			"&.Mui-focused fieldset": {
				borderColor: colors.greenAccent[700], // Border color when focused
			},
			backgroundColor: colors.primary[400], // Background color
		},
		"& .MuiInputBase-input": {
			color: colors.grey[100], // Text color inside input
		},
	};

	return (
		<Box display={"flex"} marginBottom={"10px"} gap={"20px"}>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => handleEditOpen()}
			>
				Create Bundle
			</Button>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => setEditCustomerModalOpen(true)}
			>
				Edit Customer Data
			</Button>
			<TextField
				label="Customer Code"
				sx={inputstyles}
				onChange={(e) => setCustomercodeDisplay(e.target.value)}
				value={customercodeDisplay}
				InputProps={{
					endAdornment: (
						<InputAdornment>
							<IconButton
								onClick={() => {
									setCustomerCode(customercodeDisplay.trim());
									setCustomercodeDisplay("");
								}}
							>
								<Search />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>

			<TextField
				label="Bundle Status"
				sx={inputstyles}
				onChange={(e) => setBundlestatusDisplay(e.target.value)}
				value={bundlestatusDisplay}
				InputProps={{
					endAdornment: (
						<InputAdornment>
							<IconButton
								onClick={() => {
									setBundleStatus(bundlestatusDisplay.trim());
									setBundlestatusDisplay("");
								}}
							>
								<Search />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>

			<FormControl sx={{...inputstyles, width:"20rem"}}>
				<InputLabel>Bundle Period</InputLabel>
				<Select
					name="period"
					value={periodDisplay}
					onChange={(e) => setPeriodDisplay(e.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								onClick={() => {
									setPeriod(periodDisplay.trim());
									setPeriodDisplay("");
								}}
							>
								<Search />
							</IconButton>
						</InputAdornment>
					}
				>
					<MenuItem value="DAILY">DAILY</MenuItem>
					<MenuItem value="WEEKLY">WEEKLY</MenuItem>
					<MenuItem value="TWOWEEKS">TWOWEEKS</MenuItem>
					<MenuItem value="MONTHLY">MONTHLY</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
};

export default BundleGridToolbar;
