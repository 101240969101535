import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
const CloverfieldAuth = () => {
	const {
		userData: { role },
	} = useSelector((state) => state.auth);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	if (role !== "grid_operator") {
		return (
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				minHeight={"80vh"}
			>
				<Box
					sx={{
						width: 400,
						bgcolor: colors.primary[400],
						color: colors.grey[100],
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
						border: `1px solid ${colors.grey[500]}`,
					}}
				>
					<Typography
						variant="h6"
						sx={{ color: colors.blueAccent[500] }}
					>
						Cloverfield is only acessible to grid operators, contact admin for credentials to manage grid operations
					</Typography>
				</Box>
			</Box>
		);
	} else {
		return <Outlet />;
	}
};

export default CloverfieldAuth;
