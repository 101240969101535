import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../config";
import { getAuthToken } from "../utils/tokenprovider";

export const AiNotificationsApi = createApi({
	reducerPath: "aiNotificationsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			const token = getAuthToken();

			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	tagTypes: ["ainotifications"],
	endpoints: (builder) => ({
		getAiNotifications: builder.query({
			query: ({ page, pagesize }) => ({
				method: "GET",
				url: "/api/v1/ai",
				params: { page:page + 1, limit: pagesize },
			}),
			providesTags: ["ainotifications"],
		}),
	}),
});

export const { useGetAiNotificationsQuery } = AiNotificationsApi;
