import { Outlet } from "react-router-dom";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const Displaylayout = () => {
	const [theme, colorMode] = useMode();

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className="app">
					<main className="content">
						<Outlet />
					</main>
				</div>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
};

export default Displaylayout;
