import { Box, useTheme } from "@mui/material";
import DaySwapChart from "./charts/day/DaySwapChart";
import HourSwapChart from "./charts/hour/HourSwapChart";
import RollingSwapChart from "./charts/rolling/RollingSwapChart";
import { tokens } from "../../../../theme";
import SwapEvents from "../../../Displayswaps/SwapEvents";
import PaymentEvents from "../../../Displayswaps/PaymentEvents";

const SwapCharts = ({mwcDashboard}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<Box
			display={"grid"}
			gridTemplateColumns={"repeat(12, 1fr)"}
			gridAutoRows="140px"
			gap="20px"
			mt={"20px"}
			sx={{
				marginTop: "20px",
				marginBottom: "40px",
			}}
		>
			<Box gridColumn="span 6" gridRow="span 3">
				<HourSwapChart />
			</Box>
			<Box gridColumn="span 6" gridRow="span 3">
				<DaySwapChart />
			</Box>
			{mwcDashboard ? (
				<>

					<Box
						gridColumn="span 6"
						gridRow="span 3"
						backgroundColor={colors.primary[400]}
						overflow="auto"
					>
						<SwapEvents />
					</Box>
					<Box
						gridColumn="span 6"
						gridRow="span 3"
						backgroundColor={colors.primary[400]}
						overflow="auto"
					>
						<PaymentEvents />
					</Box>
					<Box gridColumn="span 12" gridRow="span 3">
						<RollingSwapChart />
					</Box>
				</>
			) : (
				<>
					<Box gridColumn="span 12" gridRow="span 3">
						<RollingSwapChart />
					</Box>
				</>
			)}
		</Box>
	);
};

export default SwapCharts;
