import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAiNotifications } from "../../hooks/useAinotifications";
const PrivateRoutes = () => {
    useAiNotifications({homepage: true})

	const { isAuthenticated } = useSelector((state) => state.auth);

	return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
