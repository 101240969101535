import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from "../config";
import { AUTH_TOKEN_KEY } from "../config";
import { getAuthToken } from "../utils/tokenprovider";

const ENERGY_URL = `${BASE_URL}/api/v1/dashboard/energyusagechart`
const SWAPS_URL = `${BASE_URL}/api/v1/dashboard/swapschart`
const SWAPS_LIST =`${BASE_URL}/api/v1/swaps?page=1&limit=100`

const initialState = {
    energychart:{
        data: null, 
        loading: false, 
        error:""
    },

    swapschart:{
        data: null, 
        loading: false, 
        error:""
    }, 

    swaps:{
        data: {
            raw:[]
        }, 
        loading: false, 
        error:""
    }
}


export const fetchEnergyUsageChart = createAsyncThunk("energy/energyswapsdata", async(cabinetid, thunkApi)=>{
    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")
    try {
        const res = await fetch(`${ENERGY_URL}?cabinetid=${cabinetid}`, {
            headers: {
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.data // THIS IS AN ARRAY
        }else {
            throw new Error("ERROR WHILE ENERGY CHART DATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH ENERGY CHART DATA::", error)
        return thunkApi.rejectWithValue("Error loading energy chart data")
    }
})



export const fetchSwapsData = createAsyncThunk("swaps/swapsChartData", async(cabinetid, thunkApi)=>{
    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")
    try {
        const res = await fetch(`${SWAPS_URL}?cabinetid=${cabinetid}`, {
            headers: {
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.data // THIS IS AN ARRAY
        }else {
            throw new Error("ERROR WHILE FETCHING SWAPS CHART DATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH SWAPS CHART DATA::", error)
        return thunkApi.rejectWithValue("Error loading swaps chart data")
    }
})

export const fetchSwaps = createAsyncThunk("swaps/getSwaps", async(info, thunkApi)=>{
    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")
    
    const {cabinetid, startdate, enddate} = info
    try {
        const res = await fetch(`${SWAPS_LIST}&cabinetid=${cabinetid}&startdate=${encodeURIComponent(startdate)}&enddate=${encodeURIComponent(enddate)}`, {
            headers: {
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.SwapsLog            
        }else {
            throw new Error("ERROR WHILE FETCHING LIST OF SWAPS::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH LIST OF SWAPS::", error)
        return thunkApi.rejectWithValue("Error loading swaps data")
    }
})





const cabinetDetailsSlice = createSlice({
    name:"CabinetDetails", 
    initialState,
    reducers: {
        seedSwaps: (state, action) =>{
            state.swaps.data.raw = action.payload
        }
    },

    extraReducers: (builder) =>{
        builder
        .addCase(fetchEnergyUsageChart.pending, (state)=>{
            state.energychart.error = ""
            state.energychart.loading = true
        })
        .addCase(fetchEnergyUsageChart.fulfilled, (state, action)=>{
            state.energychart.error = ""
            state.energychart.data = {
                raw: action.payload,
            }
            state.energychart.loading = false
        })
        .addCase(fetchEnergyUsageChart.rejected, (state, action)=>{
            state.energychart.error = action.payload
            state.energychart.loading = false
        })
        .addCase(fetchSwapsData.pending, (state)=>{
            state.swapschart.error = ""
            state.swapschart.loading = true
        })
        .addCase(fetchSwapsData.fulfilled, (state, action)=>{
            state.swapschart.error = ""
            state.swapschart.data = {
                raw: action.payload,
            }
            state.swapschart.loading = false
        })
        .addCase(fetchSwapsData.rejected, (state, action)=>{
            state.swapschart.error = action.payload
            state.swapschart.loading = false
        })
        .addCase(fetchSwaps.pending, (state)=>{
            state.swaps.error = ""
            state.swaps.loading = true
        })
        .addCase(fetchSwaps.fulfilled, (state, action)=>{
            state.swaps.error = ""
            state.swaps.data = {
                raw: action.payload,
            }
            state.swaps.loading = false
        })
        .addCase(fetchSwaps.rejected, (state, action)=>{
            state.swaps.error = action.payload
            state.swaps.loading = false
        })
        
    }
})


export const CabinetDetailsApi = createApi({
    reducerPath:"cabinetDetailsApi", 
    baseQuery: fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders: (headers) => {
			const token = getAuthToken();

			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
    }),
    tagTypes:["bpoolcabinet"], 
    endpoints:(builder)=>({
        getBpoolCabinet: builder.query({
            query:({bpoolid})=>({
                method:"GET", 
                url:"http://localhost:3000/api/v1/cabinets/getbpoolcabinet", 
                params:{cabinetid: bpoolid}
            }), 
            providesTags:["bpoolcabinet"]
        }), 
        getSpecificCabinetLog: builder.query({
            query: ({time, cabinet}) => {
                return {
                    method: "GET",
                    url: "/api/v1/cabinets/specificcabinetlog",
                    params: { time, cabinet }, // ✅ Correctly append the query parameter
                };
            },
        })
    })
    
})

export const {useGetBpoolCabinetQuery, useGetSpecificCabinetLogQuery} = CabinetDetailsApi

export const  {seedSwaps} = cabinetDetailsSlice.actions
export default cabinetDetailsSlice.reducer