import { Box, IconButton, useTheme, Popover, Button } from "@mui/material";
import { useContext, useState } from "react";
import { 
  ColorModeContext, 
  tokens 
} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorElement, setAnchorElement] = useState(null)

  const handleOpen = (event)=>{
    setAnchorElement(event.currentTarget)
  }

  const handleClose = ()=>{
    setAnchorElement(null)
  }

  const logout = () => {
    localStorage.clear(); 
    window.location.reload(); 
  };

  const open = Boolean(anchorElement)
  const id = open ? "profile-popover" : undefined

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
      </Box>

      {/* ICONS */}
      <Box display="flex" >
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleOpen}>
          <PersonOutlinedIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorElement}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2} display="flex" flexDirection="column">
            {/* <Button variant="contained" color="primary" onClick={handleClose}>
              Profile
            </Button> */}
            <Button variant="outlined" color="secondary" sx={{ mt: 1 }} onClick={logout}>
              Logout
            </Button>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default Topbar;
