import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {  GRID_URL} from "../config"; // Assuming you have a config file

export const CloverfieldApi = createApi({
	reducerPath: "CloverfieldApi",
	baseQuery: fetchBaseQuery({
		baseUrl: GRID_URL
	}),
    tagTypes: ["Bundles"],
	endpoints: (builder) => ({
		// Get bundles with filters and pagination
		getBundles: builder.query({
			query: ({
				page,
				pagesize,
				customercode,
				bundlestatus,
				period,
			}) => {
				const params = new URLSearchParams();
				params.append("page", page+1);
				params.append("limit", pagesize);
				if (customercode) params.append("customercode", customercode);
				if (bundlestatus) params.append("bundlestatus", bundlestatus);
				if (period) params.append("period", period);

				return {
					url: `/grid/v1/dashboard/bundles?${params.toString()}`,
					method: "GET",
				};
			},
            providesTags: ["Bundles"],
		}),
		getUser: builder.query({
			query: ({ code }) => {
				const params = new URLSearchParams();
				if (code) params.append("code", code);
				return {
					url: `/grid/v1/dashboard/bundles/getuser?${params.toString()}`,
					method: "GET",
				};
			},
		}),
		// Create a new bundle
		createBundle: builder.mutation({
			query: ({
				code,
				period,
				employee_id,
				reasoning,
				transactionCode,
			}) => ({
				url: "/grid/v1/dashboard/bundles",
				method: "POST",
				body: { code, period, employee_id, reasoning, transactionCode },
			}),
            invalidatesTags: ["Bundles"],
		}),
		modifyUser: builder.mutation({
			query: ({ code, sparkmeter_meter_serial }) => ({
				url: `/grid/v1/dashboard/bundles/modifyuser?code=${code}`,
				method: "POST",
				body: { sparkmeter_meter_serial },
			}),
		}),
		// Deactivate a bundle
		deactivateBundle: builder.mutation({
			query: ({ id, employee_id, reasoning }) => ({
				url: `/grid/v1/dashboard/bundles`,
				method: "DELETE",
				body: { id, employee_id, reasoning },
			}),
            invalidatesTags: ["Bundles"],
		}),
	}),
});

export const {
	useLazyGetUserQuery,
	useGetBundlesQuery,
	useCreateBundleMutation,
	useDeactivateBundleMutation,
	useModifyUserMutation
} = CloverfieldApi;
