import { Box, useTheme, Typography } from "@mui/material";
import SwapCharts from "../operations/swaps/components/SwapCharts";
import { fetchTodaySwaps } from "../../state_management/cabinetDashboard";
import { useDispatch, 
//	useSelector 
} from "react-redux";
import { useEffect } from "react";
import { tokens } from "../../theme";

const Displayswaps = () => {
	const dispatch = useDispatch();
	//const { swaps } = useSelector((state) => state.cabinets);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	useEffect(() => {
		dispatch(fetchTodaySwaps());
	}, [dispatch]);

	return (
		<Box m="20px">
			<Box display="flex" alignItems="center" >
				<img alt="profile-user" width="50px" height="60px" src={`../../logoCropped.png`} style={{ marginTop:"-25px"}} />
				<Box mb="30px">
					<Typography
						variant="h2"
						color={colors.grey[100]}
						fontWeight="bold"
						sx={{ m: "0 0 5px 0" }}
					>
						SWAPS
					</Typography>
					</Box>
			</Box>
			<SwapCharts mwcDashboard />
		</Box>
	);
};

export default Displayswaps;
