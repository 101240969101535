//import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const SwapEvents = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
    const [swaps, setSwaps] = useState([])


    useEffect(()=>{
		const swapSource = new EventSource("https://asaliserver.powerhive.com/api/v1/swaps/sse")
		//const swapSource = new EventSource("http://localhost:3000/api/v1/swaps/sse")

		swapSource.onmessage = (event)=>{
			try {
				const data = JSON.parse(event.data)
				console.log(data)
				toast.success(data.message)
                setSwaps(prev => [data.message, ...prev])
			} catch (error) {
				console.error("A problem occured fetching swap event", error);
			}
		}

		swapSource.onerror = (err) => {
			console.error("swap SSE encountered an error:", err);
			toast.error("Lost connection to live swap updates.");
			swapSource.close();
		};

		return () => {
			swapSource.close();
		};
	},[])

	return (
		<>
            <Box>
                {swaps.length && swaps.map((swap) => (
                    <Box
                        key={swap}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        p="15px"
                    >
                        <Typography
                            color={colors.greenAccent[500]}
                            fontWeight="600"
                            fontSize={25}
                        >
                            {swap}
                        </Typography>
                    </Box>
                ))}
            </Box>
		</>
	);
};

export default SwapEvents;
