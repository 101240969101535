import {useState, useEffect} from "react";
import { Box, Typography, useTheme, TextField, Button , CircularProgress} from "@mui/material";
import { tokens } from "../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { useGetSpecificCabinetLogQuery } from "../../../state_management/cabinetDetails";

const BatteryHistory = ({cabinet}) => {
	const theme = useTheme(); // grab theme from material UI context
	const colors = tokens(theme.palette.mode);
	const [time, setTime] = useState(new Date().toISOString());
	const [cabinetLogTime, setCabinetLogTime] = useState("")
	const [batterydata, setBaterydata] = useState([])
	const [localLoading, setLocalLoading] = useState(false);

	const {data, isLoading} = useGetSpecificCabinetLogQuery({time, cabinet})


	const onChangeCabinetLogTime = ()=>{
		if(!cabinetLogTime) return
		setLocalLoading(true);
		setTime(new Date(cabinetLogTime).toISOString())
	}
	useEffect(()=>{
		if(data && !isLoading){
			const batData = data?.cabinetlog?.cabinet_data?.soc?.map((chargestate, index)=>{

				return data?.cabinetlog?.cabinet_data?.canId[index] ? {
					charge:chargestate,
					id:data?.cabinetlog?.cabinet_data?.canId[index]
				}
				: null
			})
			setBaterydata(batData.filter(Boolean))
			setLocalLoading(false)
		}
	},[data, isLoading])


	const columns = [
		{
			field: "id",
			headerName: "BATTERY ID",
			flex: 1,
		},
		{
			field: "charge",
			headerName: "CHARGE",
			flex: 1,
			renderCell: ({ row: { charge } }) => (
				<Box
					width="100%"
					display="flex"
					justifyContent="center"
					backgroundColor={
						parseInt(charge) > 50
							? colors.greenAccent[600]
							: colors.redAccent[500]
					}
					borderRadius="4px"
					p="5px"
				>
					<Typography color={colors.grey[100]}>
						{charge}
					</Typography>
				</Box>
			),
			align: "center",
			headerAlign: "center",
		},
	];

	return (
		<>
				<Box display={"flex"} gap={"10px"} alignItems={"center"}>
					<TextField
						label="Starting Time"
						type="datetime-local"
						value={cabinetLogTime}
						onChange={(e) => setCabinetLogTime(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
							paddingY: "15px",
						}}
						onClick={() => onChangeCabinetLogTime()}
					>
						Enter Time
					</Button>
				</Box>
				{isLoading || localLoading  
					?(<Box
						display={"flex"}
						justifyContent={"center"}
						alignContent={"center"}
						sx={{ backgroundColor: colors.primary[400], m:"20px 0", p:"20px 0" }}
					>
						<CircularProgress />
					</Box>)
					:(
						<Box
						m="20px 0"
						height="40vh"
						sx={{
							"& .MuiDataGrid-root": {
								border: "none",
							},
							"& .MuiDataGrid-cell": {
								borderBottom: "none",
							},
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: colors.blueAccent[700],
								borderBottom: "none",
							},
							"& .MuiDataGrid-virtualScroller": {
								backgroundColor: colors.primary[400],
							},
							"& .MuiDataGrid-footerContainer": {
								borderTop: "none",
								backgroundColor: colors.blueAccent[700],
							},
							"& .MuiCheckbox-root": {
								color: `${colors.greenAccent[200]} !important`,
							},
						}}
					>
						<DataGrid rows={batterydata} columns={columns} loading={isLoading} hideFooter= {true}/>
					</Box>
					)
				}

		</>
	);
};

export default BatteryHistory;
