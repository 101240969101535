import { useState } from "react";
import toast from "react-hot-toast";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useLazyGetUserQuery } from "../../../../state_management/grid";
import { useModifyUserMutation } from "../../../../state_management/grid";

const EditCustomerModal = ({modal}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [fetchUser, { data: userData }] = useLazyGetUserQuery();
    const [modifyUserData] = useModifyUserMutation()
	const [disableINput, setDisableINput] = useState(false);
	const [meterinputdisable, setMeterInputDisable] = useState(true);

	const [data, setData] = useState({
		codesubmitting: false,
		code: userData?.customer?.sparkmeter_customer_code || "",
		debt: userData?.customer?.grid_debt || "",
		balance: userData?.customer?.grid_balance || "",
		site: userData?.customer?.grid_name || "",
		meter: userData?.customer?.sparkmeter_meter_serial || "",
		phone: userData?.customer?.phone || "",
	});
	const handleFetchCustomerdata = async () => {
		if (!data.code) {
			toast.error("Please enter customer code");
			return;
		}
		setData((prev) => ({
			...prev,
			codesubmitting: true,
		}));
		try {
			const response = await toast.promise(fetchUser({ code: data.code }).unwrap(), {
				loading: "Fetching Customer data.",
				success: "Customer data fetched",
				error: "Could not fetch Customer Data",
			});
			setDisableINput(true);
            const customer = response?.customer;
            setData((prev) => ({
                ...prev,
                code: customer?.sparkmeter_customer_code || "",
                debt: customer?.grid_debt || "",
                balance: customer?.grid_balance || "",
                site: customer?.grid_name || "",
                meter: customer?.sparkmeter_meter_serial || "",
                phone: customer?.phone || "",
            }));
		} catch (error) {
			console.log("Error occured fetching customer data");
		} finally {
			setData((prev) => ({
				...prev,
				codesubmitting: false,
			}));
		}
	};

	const handleinput = (event) => {
		const { name, value } = event.target;
		setData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

    const handleSubmitData = async () => {
        if (!data.meter) {
			toast.error("Meter serial invalid");
			return;
		}
        setData((prev) => ({
			...prev,
			codesubmitting: true,
		}));
        try {
            await toast.promise(
                modifyUserData({
                    code:data.code, 
                    sparkmeter_meter_serial:data.meter
                }).unwrap(),
                {
					loading: "Changing user details",
					success: "User Details changed successfully",
					error: "Could not change user details",
				}
            )

            modal(false)
        } catch (error) {
            console.log("Error changing user details", error)
        }finally{
            setData((prev) => ({
                ...prev,
                codesubmitting: false,
            }));
        }
    }

	const inputstyles = {
		"& label": {
			color: colors.blueAccent[300],
		},
		"& label.Mui-focused": {
			color: colors.greenAccent[300],
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: colors.blueAccent[500],
			},
			"&:hover fieldset": {
				borderColor: colors.greenAccent[500],
			},
			"&.Mui-focused fieldset": {
				borderColor: colors.greenAccent[200],
			},
			backgroundColor: colors.primary[400],
		},
		"& .MuiInputBase-input": {
			color: colors.grey[100],
		},
	};
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<TextField
				label={`Customer Code`}
				name="code"
				value={data.code}
				onChange={handleinput}
				fullWidth
				disabled={disableINput}
				sx={inputstyles}
			/>

			{data?.debt && (
				<TextField
					label={`Customer Debt`}
					value={data.debt}
					fullWidth
					disabled={disableINput}
					sx={inputstyles}
				/>
			)}

			{data?.balance && (
				<TextField
					label={`Customer Balance`}
					value={data.balance}
					fullWidth
					disabled={disableINput}
					sx={inputstyles}
				/>
			)}

			{data?.site && (
				<TextField
					label={`Customer Site`}
					value={data.site}
					fullWidth
					disabled={disableINput}
					sx={inputstyles}
				/>
			)}

				<TextField
					label={`Meter Serial`}
					value={data.meter}
                    name="meter"
					fullWidth
					disabled={meterinputdisable}
                    onChange={handleinput}
					sx={inputstyles}
				/>

			{data?.phone && (
				<TextField
					label={`Phone Number`}
					value={data.phone}
					fullWidth
					disabled={disableINput}
					sx={inputstyles}
				/>
			)}

			{!userData && (
				<Button
					variant="contained"
					color="primary"
					onClick={handleFetchCustomerdata}
					disabled={data.codesubmitting}
				>
					Fetch Customer Data
				</Button>
			)}

            {userData && meterinputdisable && (
				<Button
					variant="contained"
					color="primary"
					onClick={()=>setMeterInputDisable(false)}
				>
					Click to Edit
				</Button>
			)}


            {userData && !meterinputdisable && (
				<Button
					variant="contained"
					color="primary"
					onClick={()=>handleSubmitData()}
				>
					Submit Changes
				</Button>
			)}
		</Box>
	);
};

export default EditCustomerModal;
