import React from "react";
import { Modal, Box, Typography, Button, useTheme } from "@mui/material";
import { tokens } from "../theme"; 
const CustomModal = ({ open, handleClose, title, children, close }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: colors.primary[400],
					color: colors.grey[100],
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					border: `1px solid ${colors.grey[500]}`,
				}}
			>
				{title && (
					<Typography
						variant="h6"
						sx={{ color: colors.blueAccent[500] }}
					>
						{title}
					</Typography>
				)}
				<Box sx={{ mt: 2 }}>{children}</Box>
				{close && (
					<Button
						variant="contained"
						sx={{
							mt: 2,
							bgcolor: colors.redAccent[500],
							"&:hover": { bgcolor: colors.redAccent[600] },
						}}
						onClick={handleClose}
					>
						Close
					</Button>
				)}
			</Box>
		</Modal>
	);
};

export default CustomModal;
