import { useState } from "react";
import {
	useGetBundlesQuery,
	useDeactivateBundleMutation,
	useCreateBundleMutation,
} from "../../../../state_management/grid";
import {
	Box,
	useTheme,
	Button,
	Typography,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { tokens } from "../../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { formatDateString } from "../../../../utils/dateUtils";
import CustomModal from "../../../../components/Modal";
import BundleGridToolbar from "./BundleGridToolbar";
import toast from "react-hot-toast";
import EditCustomerModal from "./EditCustomerModal";

const BundleGrid = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [page, setPage] = useState(0);
	const [pagesize, setPageSize] = useState(50);
	const [customercode, setCustomerCode] = useState("");
	const [bundlestatus, setBundleStatus] = useState("");
	const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false)
	const [period, setPeriod] = useState("");
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [modaldata, setModalData] = useState(null);
	const [CreateBundleData, setCreateBundleData] = useState({
		code: "",
		period: "DAILY",
		employee_id: "",
		reasoning: "",
		transactionCode: "",
		submitting:false
	});

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState({
		id: "",
		employee_id: "",
		reasoning: "",
		submitting: false,
	});

	const [deactivateBundle] = useDeactivateBundleMutation();
	const [createNewBundle] = useCreateBundleMutation();

	const { data, isLoading } = useGetBundlesQuery({
		page,
		pagesize,
		customercode,
		bundlestatus,
		period,
	});

	// View Details Modal
	const handleOpen = (provenance) => {
		const data = provenance.map((provenance) => {
			return {
				action: provenance?.action,
				user: provenance?.business_user,
				reason: provenance?.reasoning,
				mpesacode: provenance?.transaction_code || "",
			};
		});
		setModalData(data);
		setOpen(true);
	};

	const handleClose = () => {
		setModalData(null);
		setOpen(false);
	};

	const handleEditOpen = () => {
		setEditOpen(true);
	};

	const handleEditClose = () => {
		setEditOpen(false);
	};

	const handleCreateBundleInput = (event) => {
		const { name, value } = event.target;
		setCreateBundleData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleBundleCreateSubmit = async () => {
		const employee_id = "cd61c4f5-e41b-4a99-99ef-bfc996beedb6";

		console.log("Updated Bundle Data:", {
			...CreateBundleData,
			employee_id,
		});

		setCreateBundleData((prev) => ({
			...prev,
			submitting: true,
		}));


		try {
			await toast.promise(
				createNewBundle({
					code: CreateBundleData.code,
					period: CreateBundleData.period,
					employee_id,
					reasoning: CreateBundleData.reasoning,
					transactionCode: CreateBundleData.transactionCode,
				}).unwrap(),
				{
					loading: "Activating bundle...",
					success: "Bundle Activated successfully",
					error: "Could not create bundle",
				}
			);

			setCreateBundleData({
				code: "",
				period: "DAILY",
				employee_id: "",
				reasoning: "",
				transactionCode: "",
				submitting:false
			});
		} catch (error) {
			console.error("Error creating bundle:", error);
		} finally {
			handleEditClose();
			setCreateBundleData((prev) => ({
				...prev,
				submitting: false,
			}));
		}
	};

	const handleDeleteModalOpen = (id) => {
		setDeleteModalData({
			id,
			employee_id: "",
			reasoning: "",
		});
		setDeleteModalOpen(true);
	};

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
	};

	const handleDeleteModalInput = (event) => {
		const { name, value } = event.target;
		setDeleteModalData((prev) => ({
			...prev,
			[name]: value,
		}));
	};


	const handleDeleteModalSubmit = async () => {
		if (!deleteModalData.reasoning) {
			toast.error("Please add a reason for deactivating bundle");
			return;
		}
		const employee_id = "cd61c4f5-e41b-4a99-99ef-bfc996beedb6";
		setDeleteModalData((prev) => ({
			...prev,
			submitting: true,
		}));

		try {
			await toast.promise(
				deactivateBundle({
					id: deleteModalData.id,
					employee_id,
					reasoning: deleteModalData.reasoning,
				}).unwrap(),
				{
					loading: "Deactivating bundle...",
					success: "Bundle Deactivated successfully",
					error: "Could not deactivate bundle",
				}
			);

			setDeleteModalData({
				id: "",
				employee_id: "",
				reasoning: "",
				submitting: false,
			});
		} catch (error) {
			console.log("Could not deactivate bundle", error);
		} finally {
			handleDeleteModalClose();
			setDeleteModalData((prev) => ({
				...prev,
				submitting: false,
			}));
			
		}
	};

	const columns = [
		{
			field: "id",
			headerName: "Id ",
			flex: 0.05,
		},
		{
			field: "customer_code",
			headerName: "Customer",
			flex: 0.2,
		},
		{
			field: "bundle_period",
			headerName: "Period",
			flex: 0.2,
		},
		{
			field: "bundle_status",
			headerName: "Status",
			flex: 0.6,
			renderCell: ({ row: { bundle_status, id } }) => (
				<>
					{bundle_status !== "INACTIVE" ? (
						<Box display="flex" gap={3} alignItems={"center"}>
							<Typography width={"50px"}>
								{bundle_status}
							</Typography>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleDeleteModalOpen(id)}
							>
								DEACTIVATE BUNDLE
							</Button>
						</Box>
					) : (
						<>{bundle_status}</>
					)}
				</>
			),
		},
		{
			field: "createdAt",
			headerName: "Created on",
			flex: 0.3,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { createdAt } }) => formatDateString(createdAt),
		},
		{
			field: "kilowatt_hours_purchased",
			headerName: "Purchased KWH",
			flex: 0.2,
		},
		{
			field: "cutoff_kilowatt_hours",
			headerName: "Cutoff KWH",
			flex: 0.2,
		},
		{
			field: "kilowatt_hours_consumed",
			headerName: "Consumed KWH",
			flex: 0.2,
		},
		{
			field: "expiry_date",
			headerName: "Expires On",
			flex: 0.3,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { expiry_date } }) =>
				formatDateString(expiry_date),
		},
		{
			field: "actions",
			headerName: "Bundle Provenance",
			flex: 0.4,
			renderCell: ({ row: { bundleProvenances } }) => (
				<>
					{bundleProvenances.length ? (
						<Box display="flex" gap={1}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleOpen(bundleProvenances)}
							>
								Manualy Modified
							</Button>
						</Box>
					) : (
						<>Created Automaticaly</>
					)}
				</>
			),
		},
	];
	const inputstyles = {
		"& label": {
			color: colors.blueAccent[300],
		},
		"& label.Mui-focused": {
			color: colors.greenAccent[300],
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: colors.blueAccent[500],
			},
			"&:hover fieldset": {
				borderColor: colors.greenAccent[500],
			},
			"&.Mui-focused fieldset": {
				borderColor: colors.greenAccent[200],
			},
			backgroundColor: colors.primary[400],
		},
		"& .MuiInputBase-input": {
			color: colors.grey[100],
		},
	};
	return (
		<Box m="20px">
			<Typography
				variant="h3"
				fontWeight="bold"
				mb={"10px"}
				color={colors.greenAccent[500]}
			>
				BUNDLES
			</Typography>
			<Box
				height="75vh"
				sx={{
					"& .MuiDataGrid-root": { border: "none" },
					"& .MuiDataGrid-cell": { borderBottom: "none" },
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
				}}
			>
				<DataGrid
					loading={isLoading || !data}
					getRowId={(row) => row?.createdAt}
					rows={(data && data.bundle) || []}
					columns={columns}
					rowsPerPageOptions={[20, 50, 100]}
					page={page}
					pageSize={pagesize}
					pagination
					paginationMode="server"
					onPageChange={(newpage) => setPage(newpage)}
					onPageSizeChange={(newpagesize) => setPageSize(newpagesize)}
					rowCount={(data && data?.total) || 0}
					components={{ Toolbar: BundleGridToolbar }}
					componentsProps={{
						toolbar: {
							setCustomerCode,
							setBundleStatus,
							setPeriod,
							setEditCustomerModalOpen,
							handleEditOpen
						},
					}}
				/>
			</Box>

			{/* View Details Modal */}
			<CustomModal
				open={open}
				handleClose={handleClose}
				title="Bundle Details"
				close
			>
				{modaldata ? (
					<Box>
						{modaldata.map((item, index) => {
							return (
								<Box key={index}>
									<hr />
									<Typography>
										{item.action === "CREATE"
											? "Bundle Created"
											: "Bundle deleted"}
										{` by ${item?.user}`}
									</Typography>
									<Typography>
										Reasoning: {` ${item?.reason}`}
									</Typography>
									{item.mpesacode !== "BUNDLEDELETE" && (
										<Typography>
											Mpesa Transaction:{" "}
											{` ${item?.mpesacode}`}
										</Typography>
									)}
								</Box>
							);
						})}
					</Box>
				) : (
					<Typography>No data available</Typography>
				)}
			</CustomModal>

			{/* Edit Bundle Modal */}
			<CustomModal
				open={editOpen}
				handleClose={handleEditClose}
				title="Edit Bundle"
			>
				<Box display="flex" flexDirection="column" gap={2}>
					<TextField
						label="Customer Code"
						name="code"
						value={CreateBundleData.code}
						onChange={handleCreateBundleInput}
						fullWidth
						sx={inputstyles}
					/>

					<FormControl fullWidth>
						<InputLabel>Bundle Period</InputLabel>
						<Select
							name="period"
							value={CreateBundleData.period}
							onChange={handleCreateBundleInput}
						>
							<MenuItem value="DAILY">DAILY</MenuItem>
							<MenuItem value="WEEKLY">WEEKLY</MenuItem>
							<MenuItem value="TWOWEEKS">TWOWEEKS</MenuItem>
							<MenuItem value="MONTHLY">MONTHLY</MenuItem>
						</Select>
					</FormControl>
					<TextField
						label="Reasoning"
						name="reasoning"
						value={CreateBundleData.reasoning}
						onChange={handleCreateBundleInput}
						fullWidth
						sx={inputstyles}
					/>
					<TextField
						label="Associated MPESA code"
						name="transactionCode"
						value={CreateBundleData.transactionCode}
						onChange={handleCreateBundleInput}
						fullWidth
						sx={inputstyles}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleBundleCreateSubmit}
						disabled={CreateBundleData.submitting}
					>
						Create Bundle
					</Button>
				</Box>
			</CustomModal>

			{/* Delete bundle modal */}
			<CustomModal
				open={deleteModalOpen}
				handleClose={handleDeleteModalClose}
				title="Deactivate Bundle"
			>
				<Box display="flex" flexDirection="column" gap={2}>
					<TextField
						label={`Reasoning for deavtivating bundle ${deleteModalData.id}`}
						name="reasoning"
						value={deleteModalData.reasoning}
						onChange={handleDeleteModalInput}
						fullWidth
						sx={inputstyles}
					/>

					<Button
						variant="contained"
						color="primary"
						onClick={handleDeleteModalSubmit}
						disabled={deleteModalData.submitting}
					>
						Deactivate Bundle
					</Button>
				</Box>
			</CustomModal>

			<CustomModal
				open={editCustomerModalOpen}
				handleClose={()=> setEditCustomerModalOpen(false)}
				title="Customer Details"
			>
				<EditCustomerModal modal={setEditCustomerModalOpen}/>
			</CustomModal>
		</Box>
	);
};

export default BundleGrid;
