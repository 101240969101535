import { Box } from '@mui/material'
import NotificationDataGrid from './notificationDataGrid'

const Ainotification = () => {

    return (
        <Box>
            <NotificationDataGrid />
        </Box>
    )
}

export default Ainotification