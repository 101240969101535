import { Button, useTheme, Box } from "@mui/material";
import { useGetAiNotificationsQuery } from "../state_management/aiNotifications";
import {  useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { tokens } from "../theme";

const useAiNotifications = ({homepage}) => {
    const [page, setPage] = useState(0);
	const [pagesize, setPageSize] = useState(50);
    const {data, isLoading, refetch} = useGetAiNotificationsQuery({ page, pagesize })
    const navigate = useNavigate()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    useEffect(() =>{
        if(homepage){
            const aiNotificationSource = new EventSource("https://asaliserver.powerhive.com/api/v1/ai/sse")
            // const aiNotificationSource = new EventSource("http://localhost:3000/api/v1/ai/sse")
            
            aiNotificationSource.onmessage = (event)=>{
                try {
                    const data = JSON.parse(event.data)
                    toast((t)=>(
                        <Box sx={{
                            width:"350px",
                            display:"flex",
                            alignItems:"center", 
                            gap:"10px",
                            padding:"5px", 
                            backgroundColor:"white"
                        }}>
                            <p>There is a new ai reccomendation, click to view</p>
                            <Button 
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                                onClick={()=>{
                                    toast.dismiss(t.id)
                                    navigate("/ai")
                                }}
                            >View Notification</Button>
                        </Box>
                    ),
                {
                    duration:Infinity, 
                    style:{
                        maxWidth:"none"
                    }
                })
                    console.log(data.message)
                    refetch()
                } catch (error) {
                    console.error("A problem occured fetching Ai Notification event", error);
                }
            }

            aiNotificationSource.onerror = (err) => {
                console.error("Ai notification SSE encountered an error:", err);
                aiNotificationSource.close();
            };

            return () => {
                aiNotificationSource.close();
            };
        }


    },[refetch, homepage, navigate, colors])

    return {
        aiNotifications:data, 
        aiNotificationsLoading: isLoading,
        setPage,
        setPageSize, 
        page, 
        pagesize
    }
}

export {useAiNotifications} 