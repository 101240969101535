
import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from '../../../theme';
import { DataGrid } from "@mui/x-data-grid";
import { useAiNotifications } from '../../../hooks/useAinotifications';
import { formatDateString } from '../../../utils/dateUtils';
import toast from "react-hot-toast";
const NotificationDataGrid = () => {
  const theme = useTheme();
	const colors = tokens(theme.palette.mode);

  const {aiNotifications, setPage, setPageSize, page, pagesize, aiNotificationsLoading} = useAiNotifications({homepage: false})

	const columns = [
    {
			field: "dismissed_by",
			headerName: "",
			flex: 0.4,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { dismissed_by } }) => {
        if(dismissed_by){
          return "Reccomendation Apply"
        }
        return (
          <Button
          variant="Primary"
          sx={{
            backgroundColor: colors.blueAccent[500],
            color: colors.blueAccent[900],
          }}
          onClick={()=>{
            toast("Applying reccomendation")
          }}
        >
          Apply Reccomendation
        </Button>
        )
      },
		},
		{
			field: "createdAt",
			headerName: "Created",
			flex: 0.4,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { createdAt } }) => {
				return `${formatDateString(createdAt)}`;
			},
		},
		{
			field: "affects",
			headerName: "Affects",
			flex: 0.4
		},
		{
			field: "customer_ids",
			headerName: "Customer id",
			flex: 0.4,
      renderCell:({row: {customer_ids}}) =>{
				return <Box>{customer_ids?.map?.((id)=>(<Box key={id}>{id}</Box>))}</Box>
			}
		},
		{
			field: "product",
			headerName: "Product Affected",
			flex: 0.2,
		},
		{
			field: "notification_text",
			headerName: "Action",
			flex: 0.4,
		},
    {
			field: "reasoning",
			headerName: "Reasoning",
			flex: 1,
      minWidth: 200,
      renderCell:({row: {reasoning}}) =>{
				return (
					<Box
						width={"100%"}
						height={"200op"}
            sx={{
              width: "100%",
              minHeight: "50px", 
              display: "flex",
              alignItems: "center",
              whiteSpace: "normal", 
              wordBreak: "break-word", 
            }}
					>
						<Typography>
							{reasoning}
						</Typography>
					</Box>
				);
			}
		},
	];
  return (
    <Box m="20px">
    <Typography
      variant="h3"
      fontWeight="bold"
      mb={"10px"}
      color={colors.greenAccent[500]}
    >
      Ai reccomendations
    </Typography>
    <Box
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
      }}
    >
      <DataGrid
        loading={aiNotificationsLoading || !aiNotifications}
        getRowId={(row) => row?.createdAt}
        rows={(aiNotifications && aiNotifications?.AINotifications) || []}
        columns={columns}
        rowsPerPageOptions={[20, 50, 100]}
        page={page}
        pageSize={pagesize}
        pagination
        paginationMode="server"
        onPageChange={(newpage) => setPage(newpage)}
        onPageSizeChange={(newpagesize) => setPageSize(newpagesize)}
        rowCount={(aiNotifications && aiNotifications?.total) || 0}
      />
    </Box>
  </Box>
  )
}

export default NotificationDataGrid