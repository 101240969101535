const getStartOfDay = (timestamp) => {
	const date = new Date(timestamp);
	const midnight = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()
	);
	return midnight.toISOString();
};

function formatDateString(dateString) {
    // Parse the input string into a Date object
    const date = new Date(dateString);

    // Format components with leading zeros
    const pad = (num) => String(num).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



export { getStartOfDay, formatDateString };
