import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PaidIcon from '@mui/icons-material/Paid';
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
		<Box
			sx={{
				"& .pro-sidebar-inner": {
					background: `${colors.primary[400]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 35px 5px 20px !important",
				},
				"& .pro-inner-item:hover": {
					color: "#868dfb !important",
				},
				"& .pro-menu-item.active": {
					color: "#6870fa !important",
				},
			}}
			className="sidebar"
		>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape="square">
					{/* LOGO AND MENU ICON */}
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: "10px 0 20px 0",
							color: colors.grey[100],
						}}
					>
						{!isCollapsed && (
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								ml="15px"
							>
								<Typography
									variant="h3"
									color={colors.grey[100]}
								>
									Powerhive
								</Typography>
								<IconButton
									onClick={() => setIsCollapsed(!isCollapsed)}
								>
									<MenuOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</MenuItem>

					{!isCollapsed && (
						<Box mb="40px">
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<img
									alt="profile-user"
									width="70px"
									height="90px"
									src={`../../logoCropped.png`}
									style={{ cursor: "pointer" }}
								/>
							</Box>
						</Box>
					)}

					<Box paddingLeft={isCollapsed ? undefined : "10%"}>
						<Item
							title="Dashboard"
							to="/"
							icon={<HomeOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>

						{!isCollapsed && (
							<Typography
								variant="h6"
								color={colors.grey[300]}
								sx={{ m: "20px 0 20px 20px" }}
							>
								Operations
							</Typography>
						)}

						<Item
							title="Cabinets"
							to="/cabinets"
							icon={<StorageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Batteries"
							to="/batteries"
							icon={<BatteryCharging80Icon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Clients"
							to="/clients"
							icon={<ElectricBikeIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Payments"
							to="/payments"
							icon={<PaidIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Swaps"
							to="/swaps"
							icon={<SwapHorizontalCircleIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Cloverfield"
							to="/cloverfield"
							icon={<SolarPowerIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Ai Reccomendations"
							to="/ai"
							icon={<AutoAwesomeIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
            			<Item
							title="Team"
							to="/swaps"
							icon={<GroupIcon />}
							selected={selected}
							setSelected={setSelected}
						/>


						{/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu  title={
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 " }}
              >
                Charts
              </Typography>
            }
              icon={<BarChartOutlinedIcon />}
              style={{
                color: colors.grey[100],
              }}
            >
              <Item
                title="Bar Chart"
                to="/bar"
                icon={<BarChartOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Pie Chart"
                to="/pie"
                icon={<PieChartOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Line Chart"
                to="/line"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu> */}
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
  );
};

export default Sidebar;
