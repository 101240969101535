import { Route, Routes } from "react-router-dom";
import Login from "./scenes/Authentication/Login";
import PrivateRoutes from "./scenes/Authentication/PrivateRoutes";
import Bar from "./scenes/bar";
import CabinetMap from "./scenes/cabinetMap";
//import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Layout from "./scenes/Layout";
import Line from "./scenes/line";
import Payments from "./scenes/operations/payments";
import Pie from "./scenes/pie";
import Team from "./scenes/team";
import { Toaster } from "react-hot-toast";
import CabinetHomepage from "./scenes/operations/cabinets";
import Clients from "./scenes/operations/clients";
import Operationpayments from "./scenes/operations/payments";
import Batteries from "./scenes/operations/batteries";
import CabinetDetails from "./scenes/operations/cabinets/CabinetDetails";
import BatteryDetail from "./scenes/operations/batteries/BatteryDetail";
import Swaps from "./scenes/operations/swaps";
import Bikemap from "./scenes/livebikemap";
import Displayswaps from "./scenes/Displayswaps/swaps";
import Displaylayout from "./scenes/Displayswaps/layout";
import Ainotification from "./scenes/operations/aiNotifications";
import CloverField from "./scenes/operations/cloverfield";
import CloverfieldAuth from "./scenes/operations/cloverfield/CloverfieldAuth";

function App() {

  return (
    <>
          <Routes>
          {/* Public routes */}
          <Route path="/cabinetmap" element={<CabinetMap />}/>
          <Route path="/bikes" element={<Bikemap />} />
          <Route path="/login" element={<Login />} />
          <Route element={<Displaylayout />}>
            <Route path="/displayswaps" element={<Displayswaps />}/>
          </Route>
          <Route element={<Layout/>}>
              <Route element={<PrivateRoutes />}>
                  {/* <Route path="/" element={<Dashboard />} /> */}
                  <Route path="/" element={<CabinetHomepage />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/swaps" element={<Swaps />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/ai" element={<Ainotification />} />
                  <Route path="cabinets" element={<CabinetHomepage />}/>
                  <Route element={<CloverfieldAuth />}>
                    <Route path="cloverfield" element={<CloverField />}/>
                  </Route>
                  <Route path="cabinets/:cabinetid" element={<CabinetDetails />}/>
                  <Route path="batteries" element={<Batteries />} />
                  <Route path="batteries/:batteryid" element={<BatteryDetail />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="payments" element={<Operationpayments />} />
              </Route>
          </Route>
        </Routes>
        <Toaster />
    </>

  );
}

export default App;
