import { useTheme } from "@mui/material";
import { tokens } from "../../../../../../theme";
import { AgCharts } from "ag-charts-react";

const Chart = ({ isCustomLineColors = false, data }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const daysOfWeek = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	];

	function transform(data) {
		const days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		const today = new Date().getDay().toLocaleString();
		const currentday = days[today];

		const currentdayindex = daysOfWeek.indexOf(currentday);
		return data.map((item, index) => {
			return currentdayindex >= index
				? {
          ...item, 
          previous_week_swaps: Number(item.previous_week_swaps),
          current_week_swaps: Number(item.current_week_swaps),
        }
				: {
						...item,
						current_week_swaps: null,
						previous_week_swaps: Number(item.previous_week_swaps),
				  };
		});
	}

	const transformedData = transform(data);

	return (
		<AgCharts
			options={{
				series: [
					{
						type:"bar",
						xKey: "day",
						yKey: "previous_week_swaps",
						yName: "Last Week",
					},
					{
						type:"bar",
						xKey: "day",
						yKey: "current_week_swaps",
						connectMissingData: false,
						yName: "This Week",
						interpolation: { type: "smooth" },
					},
				],
				data: transformedData,
				title: {
					text: "Swaps By Day",
					color: colors.grey[100],
					fontSize: 25,
					fontWeight: "bold",
				},
				subtitle: {
					text: "Swaps By day compared to last week",
					color: colors.greenAccent[500],
					fontSize: 15,
				},
				height: 450,
				background: {
					fill: colors.primary[400],
				},
				axes: [
					{
						type: "category",
						position: "bottom",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Days",
							color: colors.grey[100],
						},
					},
					{
						type: "number",
						position: "left",
						label: {
							rotation: 0,
							color: colors.grey[100],
						},
						title: {
							enabled: true,
							text: "Number of Swaps",
							color: colors.grey[100],
						},
					},
				],
				legend: {
					item: {
						label: {
							color: colors.grey[100],
						},
					},
				},
			}}
		/>
	);
};

export default Chart;
